<template>
<CFooter :fixed="false">

    <CCol sm="3" class="logo-mobile">
        <CImg name="logo" class="logo-ipad" height="35" alt="Logo" src="img/logo/logo.png"></CImg>
    </CCol>

    <CCol sm="3">
        <ul class="link link-color mobile-text-align">
            <li class="active-color" @click="redirectToRoute('/pages/aboutus')">About Us</li>
            <li class="active-color" @click="redirectToRoute('/pages/contactus')">Contact Us</li>
            <li class="active-color" @click="redirectToRoute('/pages/aboutus')">Terms and Conditions</li>
           
        </ul>
    </CCol>

    <CCol sm="3">
        <ul class="link link-color mobile-text-align">
            <li class="active-color" @click="downloadPdf('privacy_policy')">Privacy policy</li>
            <li class="active-color">Software Service Agreement</li>
        </ul>
    </CCol>
    <CCol sm="3">
        <div class="social-link">
            <div class="Column">
                <CIcon name="cib-facebook" height="30" class="social-color" />
            </div>
            <div class="Column">
                <CIcon name="cib-youtube" height="30" class="social-color" />
            </div>
            <div class="Column">
                <CIcon name="cib-instagram" height="30" class="social-color" />
            </div>
        </div>
         <hr class="solid  d-md-none">

    </CCol>

    <hr class="dashed">

    <div class="bottom-tag ml-3">
        <a class="link-color" href="" target="_blank">ScanMe</a>
        <span class="ml-1 link-color">&copy; {{new Date().getFullYear()}}.</span>
    </div>
    <div class="mfs-auto bottom-tag  mr-3">
        <span class="mr-1 link-color" target="_blank">Powered by</span>
        <a class="link-color active-colo" target="_blank" href="https://www.tryonics.com/">Tryonics</a>
    </div>

</CFooter>
</template>

<script>
export default {
    name: 'TheFooter',
    methods:{
        redirectToRoute(param_route){
            let route = this.$router.resolve({
                path: param_route,
            });
            window.open(route.href, "_blank");
        },
        downloadPdf(type){
            if(type == "privacy_policy"){
                window.open(process.env.VUE_APP_CUSTOMER_PORTAL_BASE_URL + 'PrivacyPolicy.pdf', "_blank");
            }
        }
    }
}
</script>

<style scoped>
.c-footer {
    color: #3c4b64;
    background: #09092F;
    border-top: 1px solid #d8dbe0;
    height: auto;
    padding-top: 30px;
}

.bottom-tag {
    padding-bottom: 20px;
    padding-top: 10px;
}

.link {
    font-size: 0.9rem;
}

.link-color {
    color: #ffffff !important;
    cursor: pointer;
}

.active-color:hover {
    color: #8d60ff !important;
}

ul {
    list-style-type: none;
}

ul li {
    padding-bottom: 10px;
}

.social-color {
    color: #ffffff;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.social-color:hover {
    color: #a443f3;
    cursor: pointer;
}

.social-link {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
}

hr.solid {
    border-top: 0.12rem solid rgb(255, 255, 255);
}

@media (max-width: 768px) {
    .logo {
        margin-bottom: 50px;
    }
}

@media (max-width: 575.98px) {

    .logo-mobile {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        margin-bottom: 25px;
    }

    .logo-ipad{
         height: 30px !important;
    }

    .mobile-text-align {
        text-align: center;
        margin-left: -50px;

    }

    .active-color {
        padding-bottom: 20px;
    }

    .social-link {
        padding-top: 5px;
        padding-bottom: 10px;
    }

}

@media (min-width: 768px) and (max-width: 991.98px) {
   .logo-ipad{
     
   height: 25px !important;
   }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
   .logo-ipad{
     
   height: 32px !important;
   }
}
</style>
