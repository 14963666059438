<template>
<div>
    <vue-snotify></vue-snotify>
    <div class="bg-image">
        <CRow>
            <CCol md="6">
                <CCard class="card-margin">
                    <CRow>
                        <CCol md="12">
                            <div class="p-5">
                                <h1 class="txt-color text-center pt-2">
                                    Registration Form
                                </h1>
                                <ValidationObserver ref="request_form" v-slot="{ passes }">
                                    <CForm @submit.prevent="passes(createRequest)">
                                        <ValidationProvider vid="restaurant_name" name="Name of the place" v-slot="{ errors }">
                                            <CInput class="mt-5" addInputClasses="to-uppercase" placeholder="Name of the place" size="lg" v-model="restaurant_name" v-uppercase />

                                            <CAlert v-if="errors[0]" class="error-text" color="danger" closeButton>
                                                <p v-for="(error, index) in errors" v-bind:key="index">
                                                    {{ error }}
                                                </p>
                                            </CAlert>
                                        </ValidationProvider>

                                        <ValidationProvider vid="first_name_cp" name="Contact person First Name" v-slot="{ errors }">
                                            <CInput class="mt-5" addInputClasses="to-uppercase" placeholder="First name" size="lg" v-model="first_name_cp" v-uppercase />
                                            <CAlert v-if="errors[0]" class="error-text" color="danger" closeButton>
                                                <p v-for="(error, index) in errors" v-bind:key="index">
                                                    {{ error }}
                                                </p>
                                            </CAlert>
                                        </ValidationProvider>

                                        <ValidationProvider vid="last_name_cp" name="Contact person Last Name" v-slot="{ errors }">
                                            <CInput class="mt-5" addInputClasses="to-uppercase" placeholder="Last name" size="lg" v-model="last_name_cp" v-uppercase />
                                            <CAlert v-if="errors[0]" class="error-text" color="danger" closeButton>
                                                <p v-for="(error, index) in errors" v-bind:key="index">
                                                    {{ error }}
                                                </p>
                                            </CAlert>
                                        </ValidationProvider>

                                        <ValidationProvider vid="mobile_no" name="Mobile Number" v-slot="{ errors }">
                                            <CInput class="mt-5" size="lg" v-model="mobile_no" v-mask="'+94#########'" placeholder="(+94)-XXXXXXXXX" />

                                            <CAlert v-if="errors[0]" class="error-text" color="danger" closeButton>
                                                <p v-for="(error, index) in errors" v-bind:key="index">
                                                    {{ error }}
                                                </p>
                                            </CAlert>
                                        </ValidationProvider>

                                        <ValidationProvider vid="email" name="Email Address" v-slot="{ errors }">
                                            <CInput class="mt-5" placeholder="EMAIL ADDRESS" size="lg" v-model="email" v-lowercase />

                                            <CAlert v-if="errors[0]" class="error-text" color="danger" closeButton>
                                                <p v-for="(error, index) in errors" v-bind:key="index">
                                                    {{ error }}
                                                </p>
                                            </CAlert>
                                        </ValidationProvider>

                                        <CButton color="primary mt-5 mb-3 btn-size" size="lg" block type="submit">
                                            Register
                                        </CButton>
                                    </CForm>
                                </ValidationObserver>
                            </div>
                        </CCol>
                    </CRow>
                </CCard>
            </CCol>
            <CCol class="d-sm-down-none d-md-down-none" md="6">
                <div class="">
                    <div class="heading-margin">
                         <h1 class="heading-font-size">
              We bring your favourite <br />
              restaurants at your fingertips<br />
              <b class="heading-txt-color">Exceptional Dining Experience Starts Here...</b><br />
            </h1>
                    </div>
                </div>
            </CCol>
        </CRow>
    </div>
</div>
</template>

<script>
import TheHeader from "../../../containers/TheHeader.vue";
import TheFooter from "../../../containers/TheFooter.vue";
import {
    mapState,
    mapActions
} from "vuex";
export default {
    name: "Page500",
    components: {
        TheHeader,
        TheFooter,
    },
    data() {
        return {
            isReset: 0,
            isLoading: false,
            fullPage: true,
            //form data
            first_name_cp: "",
            last_name_cp: "",
            mobile_no: "",
            email: "",
            restaurant_name: "",
        };
    },
    computed: {},
    async mounted() {
        this.clearForm();
    },
    methods: {
        ...mapActions("restaurant", ["signupRestaurantRequest"]),
        ...mapActions("settings", ["cuisineList"]),
        //create signup request
        async createRequest() {
            this.isLoading = true;
            const isValid = await this.$refs.request_form.validate();
            if (isValid) {
                let formData = new FormData();
                formData.append("first_name_cp", this.first_name_cp);
                formData.append("last_name_cp", this.last_name_cp);
                formData.append("mobile_no", this.mobile_no);
                formData.append("email", this.email);
                formData.append("restaurant_name", this.restaurant_name);
                formData.append("restaurant_contact_no", this.restaurant_contact_no);

                this.signupRestaurantRequest(formData).then((data) => {
                    if (data && data.data.error) {
                        this.$refs.request_form.setErrors(data.data.error);
                    } else {
                        this.clearForm();
                    }
                    this.isLoading = false;
                });
            }
        },

        //clear registration form data
        clearForm() {
            this.first_name_cp = "";
            this.last_name_cp = "";
            this.mobile_no = "";
            this.email = "";
            this.restaurant_name = "";
        },
    },
};
</script>

<style>
  .form-control {

    border: 1px solid ;
    color: #ffffff !important;
    background-color: rgba(255, 255, 255, 0) !important;
}

</style>

<style scoped>
.card-margin {
    margin: 5rem !important;
     background-color: rgba(0, 0, 0, 0.822);
}


.bg-image {
    background-image: url("../../../../public/img/bg_images/bg_image.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.heading-margin {
  padding-top: 12vh;
  padding-left: 5vw;
  box-sizing: border-box;
}

.heading-font-size {
    font-size: 3.2rem;
    color: #ffffff;
}

.heading-txt-color {
    color: #be70fd;
    font-size: 4rem;
}

.btn-size {
    height: 54px;
}

.txt-color {
    color: #ffffff;
}

@media (max-width: 768px) {
  .card-margin {
    margin: 1rem !important;
    background-color: rgba(0, 0, 0, 0);
     border: solid #ffffff00;
  }


}

@media (min-width: 768px) and (max-width: 991.98px) {
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
}
}
</style>
